<template>
    <div class="sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#324157"
            text-color="#bfcbd9"
            active-text-color="#20a0ff"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.title }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
// import bus from "../common/bus";
export default {
    data() {
        return {
            items:JSON.parse(localStorage.getItem("itemRouter")),

            
            // items: [
            //     {
            //         icon: "el-icon-lx-home",
            //         index: "dashboard",
            //         title: "系统首页"
            //     },
            //     // {
            //     //     icon: "el-icon-lx-cascades",
            //     //     index: "table",
            //     //     title: "酒店管理"
            //     // },
            //     // {
            //     //     icon: "el-icon-takeaway-box",
            //     //     index: "guestroom",
            //     //     title: "客房管理"
            //     // },
            //     // {
            //     //     icon: "el-icon-c-scale-to-original",
            //     //     index: "orderList",
            //     //     title: "订单管理"
            //     // },
            //     {
            //         icon: "el-icon-lx-warn",
            //         index: "7",
            //         title: "系统管理",
            //         subs: [
            //             {
            //                 index: "permission",
            //                 title: "权限测试"
            //             },
            //             {
            //                 index: "rolemanagement",
            //                 title: "角色分配"
            //             },
            //             {
            //                 index: "menumanagement",
            //                 title: "菜单管理"
            //             },
            //             {
            //                 index: "administrators",
            //                 title: "管理员"
            //             },
            //             {
            //                 index: "basicsetup",
            //                 title: "酒店基础设置"
            //             },
            //             {
            //                 index: "package",
            //                 title: "套餐管理"
            //             },
            //         ]
            //     }
            // ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("/", "");
        },
        collapse(){
            return this.$store.state.collapse
        }
    },
    created() {
        this.getRouter()
        
    },
    methods:{
        getRouter(){
            // var itemrouter = localStorage.getItem("itemRouter")
            // console.log(JSON.parse(itemrouter));
            // this.items = itemrouter
            // // $.each(itemrouter,function (ind,val) {
            // //     console.log(val);
            // // })
            // this.items = itemrouter
        }

    },
};
</script>

<style scoped>
.sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.sidebar > ul {
    height: 100%;
}
</style>
