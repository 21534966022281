import "core-js/modules/es.function.name.js";
export default {
  data: function data() {
    return {
      title: '',
      fullscreen: false,
      name: "linxin",
      message: 2
    };
  },
  created: function created() {
    var username = localStorage.getItem("ms_username");

    if (username == 'admin' || username == 'weijubao888') {
      this.title = '后台管理系统';
    } else {
      this.title = '酒店管理端';
    }
  },
  computed: {
    username: function username() {
      var username = localStorage.getItem("ms_username");
      return username ? username : this.name;
    },
    collapse: function collapse() {
      return this.$store.state.collapse;
    }
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand: function handleCommand(command) {
      if (command == "loginout") {
        localStorage.removeItem("ms_username");
        this.$router.push("/login");
      }
    },
    // 侧边栏折叠
    collapseChage: function collapseChage() {
      this.$store.commit("hadndleCollapse", !this.collapse);
    }
  },
  mounted: function mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  }
};