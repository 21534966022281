import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import 'tinymce/skins/ui/oxide/skin.css'

// import TEditor from '@/components/myTinymce.vue'
// Vue.component('TEditor',TEditor)

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

const app = createApp(App)
installElementPlus(app)
app
    .use(store)
    .use(router)
    // .component('TEditor',TEditor)
    .mount('#app')