import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

// 酒店管理  --  新增酒店
// import addhotel from "@/views/hotelTable/addhotel.vue"

// // 酒店客房管理  --  新增客房
// import addguestr from "@/views/homeTable/addguestr.vue"
// // 角色管理
// import rolemanagement from "@/views/Supertube/rolemanagement.vue"
// // 菜单管理
// import menumanagement from "@/views/Supertube/menumanagement.vue"

var roterArr = JSON.parse(localStorage.getItem("routers"))

    
const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import("@/views/Login.vue")
    },{
        path: "/",
        name: "Home",
        component: Home,
        children:[]
    }
]
if(roterArr !=null){
    for (let i = 0; i < roterArr.length; i++) {
        const element = roterArr[i];
        var obj = {
            path: element.path,
            name: element.name,
            meta: {
                title: element.meta.title
            },
            component: () => import(`@/views/${element.component}`),
        }
        routes[2].children.push(obj)
    }
}else{
    routes
}

// const routes = [
//     {
//         path: '/',
//         redirect: '/dashboard'
//     }, {
//         path: "/",
//         name: "Home",
//         component: Home,
//         children:JSON.parse(localStorage.getItem("routers")) 
//         // children: [
//         //     // {
//         //     //     path: "/dashboard",
//         //     //     name: "dashboard",
//         //     //     meta: {
//         //     //         title: '系统首页'
//         //     //     },
//                 // component: () => import(`@/views${element.component}`),
//         //     //     component: () => import("@/views/Dashboard.vue")
//         //     // }, 
//         //     // {
//         //     //     path: "/table",
//         //     //     name: "hotelTable",
//         //     //     meta: {
//         //     //         title: '酒店管理'
//         //     //     },
//         //     //     component: () => import("@/views/hotelTable/hotelTable.vue")
//         //     // }, {
//         //     //     path: "/addhotel",
//         //     //     name: "addhotel",
//         //     //     meta: {
//         //     //         title: '新增酒店'
//         //     //     },
//         //     //     component: addhotel
//         //     // },  {
//         //     //     path: "/administrators",
//         //     //     name: "administrators",
//         //     //     meta: {
//         //     //         title: '管理员'
//         //     //     },
//         //     //     component: () => import("@/views/Supertube/administrators.vue")
//         //     // }, {
//         //     //     path: "/addguestr",
//         //     //     name: "addguestr",
//         //     //     meta: {
//         //     //         title: '新增客房'
//         //     //     },
//         //     //     component: addguestr
//         //     // }, {
//         //     //     path: "/guestroom",
//         //     //     name: "guestroom",
//         //     //     meta: {
//         //     //         title: '客房管理'
//         //     //     },
//         //     //     component: () => import("@/views/homeTable/guestroom.vue")
//         //     // }, {
//         //     //     path: "/orderList",
//         //     //     name: "orderList",
//         //     //     meta: {
//         //     //         title: '订单管理'
//         //     //     },
//         //     //     component: () => import("@/views/orderTable/orderList.vue")
//         //     // },{
//         //     //     path: "/basicsetup",
//         //     //     name: "basicsetup",
//         //     //     meta: {
//         //     //         title: '酒店基础设置'
//         //     //     },
//         //     //     component: () => import("@/views/Supertube/basicsetup.vue")
//         //     // },{
//         //     //     path: "/package",
//         //     //     name: "package",
//         //     //     meta: {
//         //     //         title: '套餐管理'
//         //     //     },
//         //     //     component: () => import("@/views/Supertube/package.vue")
//         //     // },
//         //     // {
//         //     //     path: "/rolemanagement",
//         //     //     name: "rolemanagement",
//         //     //     meta: {
//         //     //         title: '角色管理'
//         //     //     },
//         //     //     component: rolemanagement
//         //     // },
//         //     // {
//         //     //     path: "/menumanagement",
//         //     //     name: "menumanagement",
//         //     //     meta: {
//         //     //         title: '管理'
//         //     //     },
//         //     //     component: menumanagement
//         //     // },
//         //     // {
//         //     //     path: "/form",
//         //     //     name: "baseform",
//         //     //     meta: {
//         //     //         title: '表单'
//         //     //     },
//         //     //     component: () => import("@/views/homeTable/BaseForm.vue")
//         //     // }, {
//         //     //     path: "/tabs",
//         //     //     name: "tabs",
//         //     //     meta: {
//         //     //         title: 'tab标签'
//         //     //     },
//         //     //     component: () => import("@/views/tablist/Tabs.vue")
//         //     // }, {
//         //     //     path: "/permission",
//         //     //     name: "permission",
//         //     //     meta: {
//         //     //         title: '权限管理',
//         //     //         permission: true
//         //     //     },
//         //     //     component: () => import("@/views/error/Permission.vue")
//         //     // },
//         //     // {
//         //     //     path: "/upload",
//         //     //     name: "upload",
//         //     //     meta: {
//         //     //         title: '上传插件'
//         //     //     },
//         //     //     component: () => import("@/views/Upload.vue")
//         //     // }, {
//         //     //     path: '/404',
//         //     //     name: '404',
//         //     //     meta: {
//         //     //         title: '找不到页面'
//         //     //     },
//         //     //     component: () => import('@/views/error/404.vue')
//         //     // }, {
//         //     //     path: '/403',
//         //     //     name: '403',
//         //     //     meta: {
//         //     //         title: '没有权限'
//         //     //     },
//         //     //     component: () => import('@/views/error/403.vue')
//         //     // }
//         // ]
//     }, {
//         path: "/login",
//         name: "Login",
//         meta: {
//             title: '登录'
//         },
//         component: () => import("@/views/Login.vue")
//     }
// ];


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | vue-manage-system`;    
    const role = localStorage.getItem('ms_username');
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.meta.permission) {
        // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
        role === 'admin'
            ? next()
            : next('/403');
    } else {
        next();
    }
});

export default router;